import classNames from 'classnames';

import ImageProxy from '@next-image-proxy';

import { DeserializedStorePageImageBannerBlock } from '@api';

import { StoreBlockProps } from './StoreBlock';

const StoreBannerImageBlock = ({
  block,
  className,
  imagePriority,
}: StoreBlockProps<DeserializedStorePageImageBannerBlock>) => {
  const {
    attributes: { image, blockOptions: { imageAlt = '' } = {} },
  } = block;

  return (
    <ImageProxy
      alt={imageAlt || 'Magic Store Banner'}
      src={image}
      sizes="(min-width: 768px) 50vw, (min-width: 1400px) 33vw, 100vw"
      width={550}
      height={285}
      priority={imagePriority}
      className={classNames('object-cover w-full h-full', className)}
    />
  );
};

export default StoreBannerImageBlock;
