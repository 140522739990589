import classNames from 'classnames';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import ImageProxy from '@next-image-proxy';

import { DeserializedStorePageCarouselBlock } from '@api';

import { getMixPanelPage, MIXPANEL_EVENT_NAMES } from '@shared/api/mixPanel';
import NextLink from '@shared/common/components/NextLink';
import { useAuth } from '@shared/common/providers/AuthProvider';
import { useMixPanel } from '@shared/common/providers/MixPanelProvider';

import { StoreBlockProps } from '../StoreBlock';
import AppSlide from './AppSlide';
import HotOfferSlide from './HotOfferSlide';

const getMixpanelBannerBlockName = ({
  isHotOffer,
  isApp,
}: {
  isApp: boolean;
  isHotOffer: boolean;
}) => {
  if (isApp) {
    return 'Magic Store Big App Banner';
  }

  if (isHotOffer) {
    return 'Magic Store Hot Offer Banner';
  }

  return 'Magic Store Banner';
};

const StoreCarouselAppBlock = ({ block }: StoreBlockProps<DeserializedStorePageCarouselBlock>) => {
  const { user } = useAuth();
  const { track } = useMixPanel();
  const {
    id,
    attributes: { items },
  } = block;

  const handleBannerLinkClick = (params: Parameters<typeof getMixpanelBannerBlockName>[0]) => {
    const page = getMixPanelPage();
    const banner = getMixpanelBannerBlockName(params);

    if (user?.id && page) {
      track(MIXPANEL_EVENT_NAMES.BANNER_CLICK, {
        distinct_id: user.id,
        page,
        banner,
      });
    }
  };

  return (
    <Swiper
      key={id}
      modules={[Autoplay]}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      spaceBetween={10}
      slidesPerView="auto"
      className="!mx-0"
      wrapperClass="flex items-center aspect-[550/285]"
    >
      {items?.map((item) => {
        const showSlideInfo =
          Boolean(item.appId) ||
          Boolean(item.hotOfferId) ||
          Boolean(item.blockOptionTitle) ||
          Boolean(item.blockOptionDescription);

        const isAppSlide = Boolean(
          item.appId || item.blockOptionTitle || item.blockOptionDescription,
        );
        const isHotOfferSlide = Boolean(item.hotOfferId && !isAppSlide);

        return (
          <SwiperSlide key={item.id} className="rounded-[20px] border border-primary-200">
            <NextLink
              href={item.blockLink}
              className="flex flex-col h-full"
              onClick={() =>
                handleBannerLinkClick({ isHotOffer: isHotOfferSlide, isApp: isAppSlide })
              }
            >
              <div
                className={classNames('rounded-2xl overflow-hidden flex-1', {
                  'rounded-b-none': showSlideInfo,
                })}
              >
                <ImageProxy
                  alt={item.blockOptionAlt || 'Magic Store Banner'}
                  sizes="(min-width: 768px) 50vw, (min-width: 1400px) 33vw, 100vw"
                  width={800}
                  height={400}
                  quality={80}
                  className="w-full h-full object-cover"
                  src={item.image}
                />
              </div>
              <AppSlide
                item={item}
                className={classNames({ hidden: !showSlideInfo || !isAppSlide })}
              />
              <HotOfferSlide
                item={item}
                className={classNames({ hidden: !showSlideInfo || !isHotOfferSlide })}
              />
            </NextLink>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default StoreCarouselAppBlock;
