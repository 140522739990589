import { ComponentType } from 'react';

import {
  DeserializedStorePageAppsBlock,
  DeserializedStorePageBannerBlock,
  DeserializedStorePageBigAppBlock,
  DeserializedStorePageBlock,
  DeserializedStorePageCarouselBlock,
} from '@api';

import StoreAppsBlock from './StoreAppsBlock';
import StoreBannerBlock from './StoreBannerBlock';
import StoreBigAppBlock from './StoreBigAppBlock';
import StoreCarouselAppBlock from './StorePageCarousel/StoreCarouselAppBlock';

export interface StoreBlockProps<
  Block extends DeserializedStorePageBlock = DeserializedStorePageBlock,
> {
  block: Block;
  page?: string;
  className?: string;
  imagePriority?: boolean;
  titleTag?: 'h2' | 'h3' | 'span';
}

export const isAppsBlock = (
  block: DeserializedStorePageBlock,
): block is DeserializedStorePageAppsBlock => {
  return block.attributes.type.startsWith('APP');
};

export const isBannerBlock = (
  block: DeserializedStorePageBlock,
): block is DeserializedStorePageBannerBlock => {
  return block.attributes.type === 'BANNER';
};

export const isCarouselBlock = (
  block: DeserializedStorePageBlock,
): block is DeserializedStorePageCarouselBlock => {
  return block.attributes.type === 'CAROUSEL';
};

export const isBigAppBlock = (
  block: DeserializedStorePageBlock,
): block is DeserializedStorePageBigAppBlock => {
  return block.attributes.type === 'BIG_APP';
};

const getBlockComponent = <Block extends DeserializedStorePageBlock>(
  block: Block,
): ComponentType<StoreBlockProps<Block>> | null => {
  if (isAppsBlock(block)) {
    return StoreAppsBlock as ComponentType<StoreBlockProps<Block>>;
  } else if (isBannerBlock(block)) {
    return StoreBannerBlock as ComponentType<StoreBlockProps<Block>>;
  } else if (isBigAppBlock(block)) {
    return StoreBigAppBlock as ComponentType<StoreBlockProps<Block>>;
  } else if (isCarouselBlock(block)) {
    return StoreCarouselAppBlock as ComponentType<StoreBlockProps<Block>>;
  }

  return null;
};

const StoreBlock = (props: StoreBlockProps) => {
  const SpecificBlock = getBlockComponent(props.block);

  if (!SpecificBlock) {
    return null;
  }

  return <SpecificBlock {...props} />;
};

export default StoreBlock;
