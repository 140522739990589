import Link from 'next/link';

import { ComponentPropsWithoutRef, ElementType } from 'react';

import classNames from 'classnames';

import ImageProxy from '@next-image-proxy';

import { DeserializedStorePageApp } from '@api';

import { getMixPanelPage, MIXPANEL_EVENT_NAMES } from '@shared/api/mixPanel';
import NextLink from '@shared/common/components/NextLink';
import { useAuth } from '@shared/common/providers/AuthProvider';
import { useMixPanel } from '@shared/common/providers/MixPanelProvider';
import { getAppStatus } from '@shared/helpers/app';

import AppHotOfferLink from './AppHotOfferLink';
import AppStatusBadge from './AppStatusBadge';
import ViewAppLink from './ViewAppLink';

type StoreAppCardProps<T extends ElementType> = {
  app: DeserializedStorePageApp;
  As?: T;
} & ComponentPropsWithoutRef<T>;

const StoreAppCard = <T extends ElementType = 'div'>({
  app,
  As,
  className,
  ...props
}: StoreAppCardProps<T>) => {
  const Component = As || 'div';
  const href = `/app/${app.attributes.appId}`;
  const offerId = app.hotOffers?.map((offer) => offer?.attributes?.offerId)?.pop();
  const appStatus = getAppStatus({
    productionReady: app.attributes.productionReady,
    status: app.attributes.status,
  });

  const { user } = useAuth();
  const { track } = useMixPanel();

  const handleLinkClick = () => {
    const page = getMixPanelPage();
    const application = app.attributes.name;

    if (user?.id && page) {
      track(MIXPANEL_EVENT_NAMES.APPLICATION_CLICK, {
        distinct_id: user.id,
        page,
        application,
      });
    }
  };

  return (
    <Component className={classNames('flex gap-3', className)} {...props}>
      <AppStatusBadge
        As={Link}
        href={href}
        className="self-start mt-1 flex-shrink-0"
        prefetch={false}
        status={appStatus}
        onClick={handleLinkClick}
      >
        <ImageProxy
          alt={`${app.attributes.name} Icon`}
          className="rounded-[0.625rem] w-[3.125rem] h-[3.125rem] flex-shrink-0"
          src={app.attributes.icon}
          width={50}
          height={50}
          data-qa="app-inside-block-image"
        />
      </AppStatusBadge>
      <div className="flex gap-1 flex-1 flex-col min-w-0">
        <NextLink
          className="truncate font-semibold text-sm"
          href={href}
          data-qa="app-name"
          onClick={handleLinkClick}
        >
          {app.attributes.name}
        </NextLink>

        <p className="text-sm font-medium truncate gap-3" data-qa="app-category-block">
          {app.attributes.category}

          {app.attributes.subcategory && (
            <>
              {' • '}
              {app.attributes.subcategory}
            </>
          )}
        </p>

        {app.attributes.shortDescription && (
          <p className="t-caption2 line-clamp-2 text-neutral-600" data-qa="app-short-description">
            {app.attributes.shortDescription}
          </p>
        )}
      </div>

      <div className={classNames('flex flex-shrink-0 h-12 gap-2 flex-col mt-1')}>
        <ViewAppLink
          className="min-h-[24px]"
          appId={app.attributes.appId}
          onClick={handleLinkClick}
        />
        {offerId && <AppHotOfferLink offerId={offerId} />}
      </div>
    </Component>
  );
};

export default StoreAppCard;
