import classNames from 'classnames';

import ImageProxy from '@next-image-proxy';

import { useHotOfferQuery } from '@query';

import { CarouselItem } from '@api';

import AngleRight from '@uikit/icons/AngleRight';

import { useHotOfferStats } from '../../HotOffersPage/HotOfferStats';

type AppSlideProps = {
  item: CarouselItem;
  className?: string;
};

export const HotOfferSlide = ({ className, item }: AppSlideProps) => {
  const hasHotOfferId = Boolean(item.hotOfferId);

  const { data: hotOffer } = useHotOfferQuery(item.hotOfferId, {
    enabled: hasHotOfferId,
  });
  const hotOfferStats = useHotOfferStats(hotOffer);

  return (
    <div
      className={classNames(
        'flex justify-center flex-col py-2 px-3 sm:px-4 3xl:p-5 gap-1 lg:gap-2.5',
        className,
      )}
    >
      <ul className="max-md:hidden flex items-center min-h-7 3xl:min-h-10 justify-between">
        {hotOfferStats?.map(({ content, icon, id }) => {
          return (
            <li key={id} className="font-bold 3xl:text-2xl gap-1 flex items-center">
              {icon} {content}
            </li>
          );
        })}
      </ul>
      <div className="flex gap-2">
        <ImageProxy
          alt={`${hotOffer?.attributes.name}'s offer icon`}
          width={40}
          height={40}
          src={hotOffer?.attributes.icon}
          className="w-[24px] h-auto rounded-full flex-shrink-0 self-start 3xl:w-10 3xl:self-center"
        />

        <div className="flex gap-[3px] flex-col justify-center overflow-hidden w-full">
          <span className="text-sm max-3xl:truncate sm:font-medium 3xl:text-base 3xl:line-clamp-2">
            {hotOffer?.attributes.name}
          </span>
          <span className="max-sm:hidden truncate text-xs text-neutral-500 font-light">
            {hotOffer?.attributes.offer}
          </span>
          <ul className="max-sm:hidden flex gap-6 md:hidden">
            {hotOfferStats?.map(({ content, icon, id }) => {
              return (
                <li key={id} className="font-semibold gap-1 flex items-center">
                  {icon} {content}
                </li>
              );
            })}
          </ul>
        </div>

        <div className="max-sm:hidden flex pl-3 items-center flex-shrink-0 3xl:items-end">
          <div className="xl:hidden w-8 h-8 flex justify-center items-center text-neutral-50 flex-shrink-0 bg-secondary rounded-full">
            <AngleRight className="icon" />
          </div>

          <span className="button button-solid-secondary button-md max-xl:hidden px-3 flex-shrink-0 3xl:button-lg">
            View Offer
          </span>
        </div>
      </div>
    </div>
  );
};

export default HotOfferSlide;
