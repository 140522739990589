import classNames from 'classnames';

import ImageProxy from '@next-image-proxy';

import { Spinner } from '@uikit';

import { useAppQuery } from '@query';

import { CarouselItem } from '@api';

import AngleRight from '@uikit/icons/AngleRight';

type AppSlideProps = {
  item: CarouselItem;
  className?: string;
};

export const AppSlide = ({ className, item }: AppSlideProps) => {
  const hasAppId = Boolean(item.appId);

  const { data: app, isLoading: isAppLoading } = useAppQuery(item.appId, {
    enabled: hasAppId,
  });

  const bannerDescription = app?.attributes.shortDescription || item?.blockOptionDescription;
  const bannerTitle = app?.attributes.appName || item?.blockOptionTitle;
  const imageAlt = app?.attributes.appName || item?.blockOptionAlt;
  const src = app?.attributes.appIcon || item?.image;

  return (
    <div
      className={classNames(
        'flex items-center min-h-[4.5rem] w-full py-2 px-5 gap-4 bg-[#FFF] rounded-b-[20px]',
        className,
      )}
    >
      {hasAppId && (
        <div className="flex shrink-0 items-center justify-center w-[40px] h-[40px] rounded-full overflow-hidden">
          {isAppLoading && <Spinner size="md" />}

          {!isAppLoading && app && (
            <ImageProxy
              alt={`${imageAlt} Icon`}
              src={src}
              width={40}
              height={40}
              className="w-full h-full"
            />
          )}
        </div>
      )}

      <div className="flex gap-1 flex-1 flex-col min-w-0">
        {(!hasAppId || !isAppLoading) && (
          <>
            <p className="font-semibold text-sm truncate">{bannerTitle}</p>

            {bannerDescription && <p className="t-caption2 line-clamp-2">{bannerDescription}</p>}
          </>
        )}
      </div>

      <div className="w-10 flex justify-center items-center text-neutral-50 flex-shrink-0 h-10 bg-secondary rounded-full">
        <AngleRight className="icon" />
      </div>
    </div>
  );
};

export default AppSlide;
