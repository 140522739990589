import classNames from 'classnames';

import NextLink, { NextLinkProps } from '@shared/common/components/NextLink';

interface ViewAppLinkProps extends Omit<NextLinkProps, 'href'> {
  appId: string;
  className?: string;
}

const ViewAppLink = ({ appId, className, ...props }: ViewAppLinkProps) => {
  return (
    <NextLink href={`/app/${appId}`} className={classNames('view-app-btn', className)} {...props}>
      View
    </NextLink>
  );
};

export default ViewAppLink;
