import classNames from 'classnames';

import MultiColorFire from '@uikit/icons/MultiColorFire';

import NextLink, { NextLinkProps } from '@shared/common/components/NextLink';

interface AppHotOfferLinkProps extends Omit<NextLinkProps, 'href'> {
  offerId: string;
  href?: NextLinkProps['href'];
  className?: string;
}

const AppHotOfferLink = ({ offerId, href, className, ...props }: AppHotOfferLinkProps) => {
  return (
    <NextLink
      href={href || `/hot-offers/${offerId}`}
      className={classNames(
        'button button-outlined button-color-secondary border-secondary-700 gap-0.5 min-h-[1rem] text-[0.5rem] px-2.5 py-0',
        className,
      )}
      {...props}
    >
      <span className="icon-[1.4em]">
        <MultiColorFire />
      </span>{' '}
      Hot Offer
    </NextLink>
  );
};

export default AppHotOfferLink;
