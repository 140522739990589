import classNames from 'classnames';

import ImageProxy from '@next-image-proxy';

import { Spinner } from '@uikit';

import { useAppQuery } from '@query';

import { DeserializedStorePageStoryBannerBlock } from '@api';

import AngleRight from '@uikit/icons/AngleRight';

import StoreBannerStoryBlockHotOffer from './StoreBannerStoryBlockHotOffer';
import { StoreBlockProps } from './StoreBlock';

const StoryBannerStoryAppBlock = ({
  block,
  className,
  imagePriority,
}: StoreBlockProps<DeserializedStorePageStoryBannerBlock>) => {
  const {
    attributes: { image, blockOptions },
  } = block;
  const { appId = '', description, title, imageAlt = '' } = blockOptions;
  const { data: app, isPending: isAppLoading } = useAppQuery(appId, {
    enabled: !!appId,
  });
  const bannerDescription = app?.attributes.shortDescription || description;
  const bannerTitle = app?.attributes.appName || title;

  return (
    <div className={classNames('flex h-full flex-col', className)}>
      <div className="flex-grow overflow-hidden">
        <ImageProxy
          alt={imageAlt || `${title} banner`}
          src={image}
          sizes="(min-width: 768px) 50vw, (min-width: 1400px) 33vw, 100vw"
          width={520}
          priority={imagePriority}
          height={200}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="flex items-center py-2 px-5 gap-4 min-h-[4.5rem]">
        {!!appId && (
          <div className="flex shrink-0 items-center justify-center w-[40px] h-[40px] rounded-full overflow-hidden">
            {isAppLoading && <Spinner size="md" />}

            {!isAppLoading && app && (
              <ImageProxy
                alt={`${app.attributes.appName} Icon`}
                src={app.attributes.appIcon}
                width={40}
                height={40}
                className="w-full h-full"
              />
            )}
          </div>
        )}

        <div className="flex gap-1 flex-1 flex-col min-w-0">
          {(!appId || !isAppLoading) && (
            <>
              <p className="font-semibold text-sm truncate">{bannerTitle}</p>

              {bannerDescription && <p className="t-caption2 line-clamp-2">{bannerDescription}</p>}
            </>
          )}
        </div>

        <div className="w-10 flex justify-center items-center text-neutral-50 flex-shrink-0 h-10 bg-secondary rounded-full">
          <AngleRight className="icon" />
        </div>
      </div>
    </div>
  );
};

const StoreBannerStoryBlock = (props: StoreBlockProps<DeserializedStorePageStoryBannerBlock>) => {
  const { hotOfferId } = props.block.attributes.blockOptions;

  if (hotOfferId) {
    return <StoreBannerStoryBlockHotOffer hotOfferId={hotOfferId} {...props} />;
  }

  return <StoryBannerStoryAppBlock {...props} />;
};

export default StoreBannerStoryBlock;
