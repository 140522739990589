import { ComponentPropsWithoutRef, ElementType } from 'react';

import classNames from 'classnames';

import {
  AppStatusValue,
  getAppStatusConfig,
  getAppStatusTextColorClassName,
} from '@shared/helpers/app';

type AppStatusBadgeProps<T extends ElementType> = ComponentPropsWithoutRef<T> & {
  status?: AppStatusValue | null;
  hideLabel?: boolean;
  labelClassName?: string;
  As?: T;
};

const AppStatusBadge = <T extends ElementType = 'div'>({
  status,
  className,
  hideLabel,
  labelClassName,
  children,
  As,
  ...props
}: AppStatusBadgeProps<T>) => {
  const Component = As || 'div';
  const config = getAppStatusConfig(status);

  if (!config) {
    return children;
  }

  const { Icon, label } = config;

  return (
    <Component
      className={classNames(
        'relative',
        status && getAppStatusTextColorClassName(status),
        className,
      )}
      {...props}
    >
      {children}

      <Icon
        className="icon-[0.875rem] absolute top-0 right-0 translate-x-1/3 -translate-y-1/3"
        data-qa="app-badge-icon"
      />

      {!hideLabel && (
        <span
          className={classNames(
            'absolute pt-0.5 font-medium t-overline text-center left-1/2 bottom-0 -translate-x-1/2 translate-y-full',
            labelClassName,
          )}
          data-qa="app-badge-text"
        >
          {label}
        </span>
      )}
    </Component>
  );
};

export default AppStatusBadge;
