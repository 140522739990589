import ImageProxy from '@next-image-proxy';

import { DeserializedStorePageBigAppBlock } from '@api';

import { getMixPanelPage, MIXPANEL_EVENT_NAMES } from '@shared/api/mixPanel';
import NextLink from '@shared/common/components/NextLink';
import { useAuth } from '@shared/common/providers/AuthProvider';
import { sendTagManagerBannerClick } from '@shared/common/providers/GoogleTagManagerProvider';
import { useMixPanel } from '@shared/common/providers/MixPanelProvider';

import { StoreBlockProps } from './StoreBlock';

const StoreBigAppBlock = ({
  block,
  imagePriority,
}: StoreBlockProps<DeserializedStorePageBigAppBlock>) => {
  const { user } = useAuth();
  const { track } = useMixPanel();

  const {
    apps: [app],
    attributes: { image },
  } = block;

  if (!app) {
    return (
      <ImageProxy
        alt="Banner"
        sizes="(min-width: 768px) 50vw, (min-width: 1400px) 33vw, 100vw"
        priority={imagePriority}
        width={550}
        height={285}
        src={image}
        className="w-full aspect-[550/285] object-cover store-block-card"
      />
    );
  }

  const appDescription = app.attributes.shortDescription || app.attributes.description;
  const href = `/app/${app.attributes.appId}`;

  const handleClick = () => {
    sendTagManagerBannerClick({
      bannerId: app.attributes.name,
    });

    const page = getMixPanelPage();

    if (user?.id && page) {
      track(MIXPANEL_EVENT_NAMES.BANNER_CLICK, {
        distinct_id: user.id,
        page,
        banner: 'Magic Store Big App Banner',
      });
    }
  };

  return (
    <div
      className="flex store-block-card overflow-hidden border border-primary-200 flex-col"
      onClick={handleClick}
      data-qa="big-app"
    >
      <NextLink href={href} className="aspect-[760/260] overflow-hidden">
        <ImageProxy
          alt={`${app.attributes.name} Banner`}
          sizes="(min-width: 768px) 50vw, (min-width: 1400px) 33vw, 100vw"
          priority={imagePriority}
          width={760}
          height={260}
          src={image}
          className="w-full h-full object-cover"
          data-qa="big-app-image"
        />
      </NextLink>
      <div className="flex items-center px-5 gap-4 h-20">
        <ImageProxy
          alt={`${app.attributes.name} Icon`}
          src={app.attributes.icon}
          width={60}
          height={60}
          className="w-[60px] h-[60px] rounded-full shrink-0"
          data-qa="big-app-icon"
        />

        <div className="flex gap-1 flex-1 flex-col min-w-0">
          <NextLink className="font-semibold text-sm truncate" href={href} data-qa="big-app-title">
            {app.attributes.name}
          </NextLink>

          {appDescription && (
            <span
              className="t-caption2 line-clamp-2 text-neutral-600"
              data-qa="big-app-description"
            >
              {appDescription}
            </span>
          )}
        </div>

        <NextLink href={href} className="view-app-btn" data-qa="big-app-view-button">
          View
        </NextLink>
      </div>
    </div>
  );
};

export default StoreBigAppBlock;
